import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, TablePagination,
  Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

const AnnouncementsTable = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("all");
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const res = await axios.get("https://medrais.com/api/announcements/getAll");
        const sorted = res.data.announcements.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAnnouncements(sorted);
      } catch (error) {
        console.error("Failed to fetch announcements:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAnnouncements();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0); // filtre değiştiğinde sayfa sıfırlansın
  };

  const filteredAnnouncements = announcements.filter(a => {
    if (filter === "all") return true;
    if (filter === "yes") return a.medical_device_related === 1 || a.medical_device_related === null;
    if (filter === "no") return a.medical_device_related === 0;
    return true;
  });
  

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Announcements
      </Typography>

      {/* Filter dropdown */}
      <FormControl sx={{ mb: 2, minWidth: 200 }}>
        <InputLabel id="filter-label">Medical Device Related</InputLabel>
        <Select
          labelId="filter-label"
          value={filter}
          label="Medical Device Related"
          onChange={handleFilterChange}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="yes">Yes</MenuItem>
          <MenuItem value="no">No</MenuItem>
        </Select>
      </FormControl>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Medical Device Related</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAnnouncements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((announcement) => (
              <TableRow key={announcement.id}>
                <TableCell><Link to= {`https://medrais.com/announcement/ann-${announcement.id}`}>{announcement.duyuru_title}</Link></TableCell>
                <TableCell>{announcement.name}</TableCell>
                <TableCell>{announcement.active === null || announcement.active === 1 ? "Yes" : "No"}</TableCell>
                <TableCell sx={{ color: announcement.medical_device_related === 0 ? "red" : "green" }}>
                  {announcement.medical_device_related === 0 ? "No" : "Yes"}
                </TableCell>
                <TableCell>{new Date(announcement.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredAnnouncements.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </Paper>
  );
};

export default AnnouncementsTable;
