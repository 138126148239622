import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, Paper,
  Button, Dialog, DialogTitle, DialogContent,
  DialogActions, Stack
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UpdateWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const res = await axios.get("https://medrais.com/api/websites/infos");
        setWebsites(res.data);
      } catch (error) {
        console.error("Data fetch error:", error);
      }
    };
    fetchWebsites();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const handleOpenDialog = (website) => {
    setSelectedWebsite(website);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedWebsite(null);
    setOpenDialog(false);
  };

  const handleUpdateImages = () => {
    navigate(`/admin/update-websites/${selectedWebsite.website_id}`);
  };

  const handleUpdateInfo = () => {
    alert("Update Website Info clicked. Implement the logic here.");
    handleCloseDialog();
  };

  return (
    <TableContainer sx={{ marginLeft: 0 }} component={Paper}>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell>Website Name</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {websites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((website) => (
            <TableRow key={website.website_id}>
              <TableCell>{website.website_name}</TableCell>
              <TableCell align="right">
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog(website)}>
                  Update
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={websites.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
        sx={{ marginLeft: 0 }}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Select Update Option</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <Button variant="outlined" onClick={handleUpdateInfo}>
              Update Website Info
            </Button>
            <Button variant="contained" color="primary" onClick={handleUpdateImages}>
              Update Website Images
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default UpdateWebsites;
