import React from 'react'
import { useState, useEffect } from "react";
import MainTopBigCard from './MainTopBigCard';
import useFormattedDate from '../Context/useFormattedDate';

const MainTop = ({ announcements }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState(announcements);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
    
        // Ekran genişliği değiştiğinde çalışacak event listener'ı ekliyoruz
        window.addEventListener("resize", handleResize);
    
        // Cleanup işlemi: Event listener'ı kaldırıyoruz
        return () => window.removeEventListener("resize", handleResize);
      }, []);
      useEffect(() => {
        if (windowWidth >= 768) {
          // Ekran genişliği 768'den büyükse, sadece ilk 3 announcement'ı al
          setFilteredAnnouncements(announcements.slice(0, 3));
        } else {
          // Ekran genişliği 768'den küçükse, tüm announcements'ı göster
          setFilteredAnnouncements(announcements);
        }
      }, [windowWidth, announcements]); // windowWidth veya announcements değiştiğinde çalışır
    const getSlugFromUrl = (url) => {
        if (!url) return "";

        return url
            .split("/")
            .filter(Boolean)
            .pop()
            .replace("?", "");
    };
    const getRegionColor = (region) => {
        switch (region) {
          case "Africa":
            return "#33a3f1";
          case "Americas":
            return "#74b430";
          case "Asia-Pacific":
            return "#aa35d6";
          case "Europe":
            return "#d6ac1b";
          case "Bodies":
            return "#f58021";
          default:
            return "#cccccc"; // Eğer eşleşme yoksa varsayılan bir renk (gri)
        }
      };

    return (
        <section className="heading-news2">
            <div className="container">
                <div
                    className="iso-call heading-news-box"
                    style={{ opacity: 1, position: "relative", height: "484.042px" }}
                >
                    <div
                        className="image-slider snd-size"
                        style={{ position: "absolute", left: 0, top: 0 }}
                    >
                        <span className="top-stories">TOP STORIES</span>
                        <div className="bx-wrapper" style={{ maxWidth: "100%" }}>
                            <div
                                className="bx-viewport"
                                style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    position: "relative",
                                    height: 482
                                }}
                            >
                                <ul
                                    className="bxslider"
                                    style={{ width: "auto", position: "relative" }}
                                >
                                    {filteredAnnouncements.map((announcement, index) => (
                                        <li key={index} style={{
                                            float: "none",
                                            listStyle: "none",
                                            position: "absolute",
                                            width: 575,
                                            zIndex: 0,
                                            display: "none"
                                        }}>
                                            <MainTopBigCard announcement={announcement} />
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div
                        className="news-post image-post default-size"
                        style={{ position: "absolute", left: 575, top: 0 }}
                    >
                        <img style={{ maxWidth: '293px', maxHeight: "235px" }} src={announcements[3]?.headlineimage ? announcements[3]?.headlineimage : announcements[3]?.image} alt="" />
                        <div className="hover-box">
                            <div className="inner-hover">
                                <a className="category-post travel" style={{ backgroundColor: getRegionColor(announcements[3]?.region) }} href="travel.html">
                                    {announcements[3]?.region}
                                </a>
                                <h2>
                                    <a
                                        href={announcements[3]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[3]?.duyuru_link)}-${announcements[3]?.id}` : announcements[3]?.duyuru_link}
                                        style={{
                                            display: "block",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginBottom: "-10px"
                                        }}
                                    >
                                        {announcements[3]?.duyuru_title}
                                    </a>
                                </h2>
                                <ul className="post-tags">
                                    <li>
                                        <i className="fa fa-clock-o" />
                                        <span>{useFormattedDate(announcements[3]?.duyuru_date)}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker" />
                                        <a href="/">{announcements[3]?.abbreviation ? announcements[3]?.country + "-"+ announcements[3]?.abbreviation : announcements[3]?.name }</a>
                                    </li>

                                </ul>
                                <p>
                                    {announcements[3]?.duyuru_content?.slice(0, 90)}

                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="news-post image-post"
                        style={{ position: "absolute", left: 862, top: 0 }}
                    >
                        <img style={{ maxWidth: '293px', maxHeight: "235px" }} src={announcements[4]?.headlineimage ? announcements[4]?.headlineimage : announcements[4]?.image} alt="" />
                        <div className="hover-box">
                            <div className="inner-hover">
                                <a className="category-post food" style={{ backgroundColor:  getRegionColor(announcements[4]?.region) }} href="food.html">
                                    {announcements[4]?.region}
                                </a>
                                <h2>
                                    <a
                                        href={announcements[4]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[4]?.duyuru_link)}-${announcements[4]?.id}` : announcements[4]?.duyuru_link}
                                        style={{
                                            display: "block",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginBottom: "-10px"
                                        }}
                                    >{announcements[4]?.duyuru_title}</a>
                                </h2>
                                <ul className="post-tags">
                                    <li>
                                        <i className="fa fa-clock-o" />
                                        <span>{useFormattedDate(announcements[4]?.duyuru_date)}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker" />
                                        <a href="/">{announcements[4]?.abbreviation ? announcements[4]?.country + "-"+ announcements[4]?.abbreviation : announcements[4]?.name }</a>
                                    </li>
                                </ul>
                                <p>
                                    {announcements[4]?.duyuru_content?.slice(0, 90)}

                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="news-post image-post"
                        style={{ position: "absolute", left: 575, top: 242 }}
                    >
                        <img style={{ maxWidth: '293px', maxHeight: "235px" }} src={announcements[5]?.headlineimage ? announcements[5]?.headlineimage : announcements[5]?.image} alt="" />
                        <div className="hover-box">
                            <div className="inner-hover">
                                <a className="category-post sport" style={{ backgroundColor:  getRegionColor(announcements[5]?.region) }} href="sport.html">
                                    {announcements[5]?.region}
                                </a>
                                <h2>
                                    <a
                                        href={announcements[5]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[5]?.duyuru_link)}-${announcements[5]?.id}` : announcements[5]?.duyuru_link}
                                        style={{
                                            display: "block",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginBottom: "-10px"
                                        }}
                                    >{announcements[5]?.duyuru_title}</a>
                                </h2>
                                <ul className="post-tags">
                                    <li>
                                        <i className="fa fa-clock-o" />
                                        <span>{useFormattedDate(announcements[5]?.duyuru_date)}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker" />
                                        <a href="/">{announcements[5]?.abbreviation ? announcements[5]?.country + "-"+ announcements[5]?.abbreviation : announcements[5]?.name }</a>
                                    </li>

                                </ul>
                                <p>
                                    {announcements[5]?.duyuru_content?.slice(0, 90)}

                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="news-post image-post"
                        style={{ position: "absolute", left: 862, top: 242 }}
                    >
                        <img style={{ maxWidth: '293px', maxHeight: "235px" }} src={announcements[6]?.headlineimage ? announcements[6]?.headlineimage : announcements[6]?.image} alt="" />
                        <div className="hover-box">
                            <div className="inner-hover">
                                <a className="category-post fashion" style={{ backgroundColor:  getRegionColor(announcements[6]?.region) }} href="fashion.html">
                                    {announcements[6]?.region}
                                </a>
                                <h2>
                                    <a
                                        href={announcements[6]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[6]?.duyuru_link)}-${announcements[6]?.id}` : announcements[6]?.duyuru_link}
                                        style={{
                                            display: "block",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginBottom: "-10px"
                                        }}
                                    >{announcements[6]?.duyuru_title} </a>
                                </h2>
                                <ul className="post-tags">
                                    <li>
                                        <i className="fa fa-clock-o" />
                                        <span>{useFormattedDate(announcements[6]?.duyuru_date)}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker" />
                                        <a href="/">{announcements[6]?.abbreviation ? announcements[6]?.country + "-"+ announcements[6]?.abbreviation : announcements[6]?.name }</a>
                                    </li>

                                </ul>
                                <p>
                                    {announcements[6]?.duyuru_content?.slice(0, 90)}

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default MainTop