import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="container"  >
        <div className="footer-widgets-part" >
          <div className="row" style={{marginBottom:-20}} >
            <div className="col-md-3">
              <div className="widget text-widget">
                <h1>About</h1>
                <p>
                MedRAIS is a global platform that provides announcements and updates on healthcare organizations and medical devices worldwide.
                </p>
              </div>
              <div className="widget social-widget">
                <h1>Stay Connected</h1>
                <ul className="social-icons">

                  <li>
                    <a href="https://www.linkedin.com/company/biomedista/" target='_blank' className="linkedin">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget categories-widget">
                <h1>Regions</h1>
                <ul className="category-list">
                  <li>
                    <a href="/">
                      Africa
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      Americas 
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      ASIA-PACIFIC
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      EUROPE
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      Bodies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget flickr-widget">
              </div>
            </div>
          </div>
        </div>
        <div className="footer-last-line">
          <div className="row">
            <div className="col-md-6">
              <p>© COPYRIGHT 2025 medrais.com</p>
            </div>
            <div className="col-md-6">
              <nav className="footer-nav">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer