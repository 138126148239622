import React, { useState } from 'react';
import Sidebar from '../../Layout/Sidebar';
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material'; // MUI'dan import

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { name, email, subject, message } = formData;
    if (!email || !name || !subject || !message) {
      alert("Fill in the required fields");
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post("https://medrais.com/api/email/sendContactEmail", formData);
      setSnackbarMessage("Message sent successfully");
      setOpenSnackbar(true);
      alert("Message sent successfully")
      setSnackbarSeverity("success");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      setSnackbarMessage("Error sending message.");
      setSnackbarSeverity("error");
    } finally {
      setIsSubmitting(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            {/* block content */}
            <div className="block-content">
              {/* contact form box */}
              <div className="contact-form-box">
                <div className="title-section">
                  <h1>
                    <span>Talk to us</span>
                  </h1>
                </div>
                <form id="contact-form" onSubmit={handleSendEmail}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="name">Name*</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="email">E-mail*</label>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="subject">Subject*</label>
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label htmlFor="message">Your Message*</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <button type="submit" id="submit-contact" disabled={isSubmitting}>
                    <i className="fa fa-paper-plane" /> {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </form>
              </div>
              {/* End contact form box */}
            </div>
            {/* End block content */}
          </div>
          <Sidebar />
        </div>
      </div>

      {/* Snackbar bileşeni */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </section>

  );
};

export default Contact;
