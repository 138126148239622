import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, Paper, Button,
  Stack, Typography, Avatar, Dialog, DialogTitle,
  DialogContent, DialogActions, TextField, FormControlLabel,
  RadioGroup, Radio
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";

const WebsiteImagesTable = () => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [filter, setFilter] = useState("all");

  // Güncelleme dialogu
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [updatedImageLink, setUpdatedImageLink] = useState("");

  // Yeni resim ekleme dialogu
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newImageLink, setNewImageLink] = useState("");
  const [imageType, setImageType] = useState("normalLogo");

  useEffect(() => {
    fetchImages();
  }, [id]);

  const fetchImages = async () => {
    try {
      const res = await axios.get(`https://medrais.com/api/websites/getImages/${id}`);
      setImages(res.data);
      setFilteredImages(res.data);
    } catch (error) {
      console.error("Resim verisi çekilemedi:", error);
    }
  };

  const applyFilter = (type) => {
    setFilter(type);
    setPage(0);

    let filtered = images;
    switch (type) {
      case "normalLogo":
        filtered = images.filter(img => img.islogo === 1 && img.isHeadline === 0);
        break;
      case "headlineLogo":
        filtered = images.filter(img => img.islogo === 1 && img.isHeadline === 1);
        break;
      case "normalImage":
        filtered = images.filter(img => img.islogo === 0 && img.isHeadline === 0);
        break;
      case "headlineImage":
        filtered = images.filter(img => img.islogo === 0 && img.isHeadline === 1);
        break;
      default:
        filtered = images;
    }

    setFilteredImages(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  const handleOpenDialog = (img) => {
    setSelectedImage(img);
    const cleanedLink = img.image.replace("https://medrais.com/medrais_images/", "");
    setUpdatedImageLink(cleanedLink);
    setOpenDialog(true);
  };
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
    setUpdatedImageLink("");
  };

  const handleUpdateImage = async () => {
    const newUpdatedLink = `https://medrais.com/medrais_images/${updatedImageLink}`
    try {
      await axios.post(`https://medrais.com/api/websites/updateSingleImage`, {
        image: newUpdatedLink,
        imageId: selectedImage.image_id
      });

      fetchImages();
      applyFilter(filter);
      handleCloseDialog();
    } catch (error) {
      console.error("Güncelleme hatası:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(`https://medrais.com/api/websites/deleteImage`, {
        imageId: id
      });
      alert("Silme başarılı");
      fetchImages();
    } catch (error) {
      console.error("Silme hatası:", error);
    }
  };

  const handleAddImage = async () => {
    const { islogo, isHeadline } = getImageTypeFlags(imageType);
    const newLink = `https://medrais.com/medrais_images/${newImageLink}`
    try {
      await axios.post(`https://medrais.com/api/websites/addimages`, {
        websiteId: id,
        image: newLink,
        islogo,
        isHeadline
      });

      fetchImages();
      setOpenAddDialog(false);
      setNewImageLink("");
      setImageType("normalLogo");
      alert("Resim başarıyla eklendi.");
    } catch (error) {
      console.error("Ekleme hatası:", error);
    }
  };

  const getImageTypeFlags = (type) => {
    switch (type) {
      case "normalLogo": return { islogo: 1, isHeadline: 0 };
      case "headlineLogo": return { islogo: 1, isHeadline: 1 };
      case "normalImage": return { islogo: 0, isHeadline: 0 };
      case "headlineImage": return { islogo: 0, isHeadline: 1 };
      default: return { islogo: 0, isHeadline: 0 };
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Websiteye Ait Resimler</Typography>
        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>Resim Ekle</Button>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button variant={filter === "normalLogo" ? "contained" : "outlined"} onClick={() => applyFilter("normalLogo")}>Normal Logo</Button>
        <Button variant={filter === "headlineLogo" ? "contained" : "outlined"} onClick={() => applyFilter("headlineLogo")}>Manşet Logo</Button>
        <Button variant={filter === "normalImage" ? "contained" : "outlined"} onClick={() => applyFilter("normalImage")}>Normal Resim</Button>
        <Button variant={filter === "headlineImage" ? "contained" : "outlined"} onClick={() => applyFilter("headlineImage")}>Manşet Resim</Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Önizleme</TableCell>
              <TableCell>Resim ID</TableCell>
              <TableCell>Tür</TableCell>
              <TableCell>Manşet</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredImages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((img) => (
              <TableRow key={img.image_id}>
                <TableCell><Avatar src={img.image} variant="rounded" sx={{ width: 60, height: 60 }} /></TableCell>
                <TableCell>{img.image_id}</TableCell>
                <TableCell>{img.islogo ? "Logo" : "Duyuru"}</TableCell>
                <TableCell>{img.isHeadline ? "Manşet" : "Normal"}</TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button variant="contained" size="small" onClick={() => handleOpenDialog(img)}>Güncelle</Button>
                    <Button variant="contained" size="small" color="error" onClick={() => handleDelete(img.image_id)}>Sil</Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredImages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Satır başına"
      />

      {/* Güncelle Dialogu */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Resmi Güncelle</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, mt: 1 }}>
          {selectedImage && (
            <>
              <Avatar src={selectedImage.image} variant="rounded" sx={{ width: 100, height: 100 }} />
              <TextField fullWidth value={updatedImageLink} onChange={(e) => setUpdatedImageLink(e.target.value)} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>İptal</Button>
          <Button variant="contained" onClick={handleUpdateImage} color="primary">Kaydet</Button>
        </DialogActions>
      </Dialog>

      {/* Resim Ekle Dialogu */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="sm" fullWidth>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
          <TextField label="Resim Adı" fullWidth value={newImageLink} onChange={(e) => setNewImageLink(e.target.value)} />
          <RadioGroup value={imageType} onChange={(e) => setImageType(e.target.value)}>
            <FormControlLabel value="normalLogo" control={<Radio />} label="Normal Logo" />
            <FormControlLabel value="headlineLogo" control={<Radio />} label="Manşet Logo" />
            <FormControlLabel value="normalImage" control={<Radio />} label="Normal Resim" />
            <FormControlLabel value="headlineImage" control={<Radio />} label="Manşet Resim" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>İptal</Button>
          <Button variant="contained" onClick={handleAddImage} color="primary">Ekle</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default WebsiteImagesTable;
