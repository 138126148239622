import React, { useEffect, useState } from 'react'
import Sidebar from '../../Layout/Sidebar'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SingleAnnouncement = () => {

  const { id } = useParams(); // :slug-:id parametresi

  const newid = id.split('-').pop(); // Slug ve ID'yi ayırıp ID'yi alıyoruz
  const [records, setRecords] = useState([]);
  const [relatedAnn, setRelatedAnn] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  
    const fetchAllRecords = async () => {
      try {
        const res = await axios.get(`https://medrais.com/api/announcements/single/${newid}`);
        setRecords(res.data);
  
        // 1. tag'leri ayıkla: "1. Pharmaceuticals, 2. Medical Devices"
        const tagString = res.data.tag || "";
        const tagArray = tagString
        .split(/[,|\n]/) // hem virgül hem de newline karakterlerine göre ayır
        .map(tag => tag.replace(/^\s*\d+\.*\s*/, "").trim()) // "1." veya "1. " baştaki numara ve noktayı sil
        .filter(tag => tag.length > 0 && isNaN(tag)); // boşları ve sadece rakam olanları sil
        console.log(tagArray + res.data.website_id )
        // 2. POST isteğini at
        const response = await axios.post("https://medrais.com/api/announcements/related2Posts", {
          tag: tagArray,
        });
        setRelatedAnn(response.data)
        console.log("İlgili duyurular:", response.data);
      } catch (error) {
        console.log("Hata:", error);
      }
    };
  
    fetchAllRecords();
  }, [id, newid]);
  

  const slug = records.duyuru_link
  ?.split("/")
  .filter(Boolean)
  .pop()
  ?.replace("?", "");

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            {/* block content */}
            <div className="block-content">
              {/* single-post box */}
              <div className="single-post-box">
                <div className="title-post">
                  <h1>
                    {records.duyuru_title}{" "}
                  </h1>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {records.duyuru_date}
                    </li>
                    <li>
                      <i className="fa fa-user" />
                      {records.name}
                    </li>
                    <li>
                      <i className="fa fa-eye" />
                      {records.viewers}
                    </li>
                  </ul>
                </div>
                <div className="post-gallery">
                  <img src={records.annimage !== null && records.annimage !== '' ? records.annimage : `https://medrais.com/medrais_images/Medraislogo.png`} alt="" />
                </div>
                <div className="post-content">
                  <p>
                    {records.duyuru_content}
                  </p>
                  <p>
                    <a href={records.duyuru_link} target='_blank'>Click here to go to the main announcement page.</a>

                  </p>
                  <p>Tags: {records.tag}</p>
                </div>
                <div className="share-post-box">
                  <ul className="share-box">
                    <li>
                      <i className="fa fa-share-alt" />
                      <span>Share Post</span>
                    </li>
                    <li>
                      <a className="facebook" href="/">
                        <i className="fa fa-facebook" />
                        Share on Facebook
                      </a>
                    </li>
                    <li>
                      <a className="twitter" href="/">
                        <i className="fa fa-twitter" />
                        Share on Twitter
                      </a>
                    </li>
                    <li>
                      <a className="google" href="/">
                        <i className="fa fa-google-plus" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin" href="/">
                        <i className="fa fa-linkedin" />
                        <span />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* carousel box */}
                <div className="prev-next-posts">
                  <h3>You may also like</h3>
                  <div className="prev-post">
                    <img src={relatedAnn[0]?.image} alt="" />
                    <div className="post-content">
                      <h2>
                      <a href={relatedAnn[0]?.duyuru_content !== "" ? `/announcement/${slug}-${relatedAnn[0]?.id}` : relatedAnn[0]?.duyuru_link}>
                          {relatedAnn[0]?.duyuru_title.slice(0,50)}
                        </a>
                      </h2>
                      <ul className="post-tags">
                        <li>
                          <i className="fa fa-clock-o" />
                          {relatedAnn[0]?.duyuru_date}
                        </li>
                        <li>
                          <a href="/">
                            <i className="fa fa-comments-o" />
                            <span>{relatedAnn[0]?.name}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="next-post">
                    <img src={relatedAnn[1]?.image}  alt="" />
                    <div className="post-content">
                      <h2>
                      <a href={relatedAnn[1]?.duyuru_content !== "" ? `/announcement/${slug}-${relatedAnn[1]?.id}` : relatedAnn[1]?.duyuru_link}>
                        {relatedAnn[1]?.duyuru_title.slice(0,50)}
                        </a>
                      </h2>
                      <ul className="post-tags">
                        <li>
                          <i className="fa fa-clock-o" />
                          {relatedAnn[1]?.duyuru_date}
                        </li>
                        <li>
                          <a href="/">
                            <i className="fa fa-comments-o" />
                            <span>{relatedAnn[0]?.name}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


                {/* End carousel box */}
                {/* contact form box */}
                {/* End contact form box */}
              </div>
              {/* End single-post box */}
            </div>
            {/* End block content */}
          </div>
          <Sidebar />
        </div>
      </div>
    </section>


  )
}

export default SingleAnnouncement