import React, { useState } from "react";
import Sidebar from "./Sidebar";
import AnnouncementCard from "./AnnouncementCard";

const MainAnnouncements = ({ announcements }) => {
  // ID'ye göre azalan şekilde sıralama
  const sortedAnnouncements = [...announcements].sort((a, b) => b.id - a.id);

  // Sayfa başına gösterilecek duyuru sayısı
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);

  // Sayfa değiştirildiğinde gösterilecek duyurular
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedAnnouncements = sortedAnnouncements.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  // Toplam sayfa sayısı
  const totalPages = Math.ceil(sortedAnnouncements.length / itemsPerPage);

  // Sayfa değiştirme fonksiyonu
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Pagination sayfa numaralarını hesapla
  const pageNumbers = () => {
    const pages = [];

    // İlk sayfa numarasını ekle
    if (currentPage > 3) pages.push(1);

    // Önceki 2 sayfa
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      if (i > 0 && i <= totalPages) {
        pages.push(i);
      }
    }
    pages.push("...")
    // Son sayfa numarasını ekle
    if (currentPage < totalPages - 2) pages.push(totalPages);

    return pages;
  };

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            {/* block content */}
            <div className="block-content">
              {/* Advertisement */}
              <div className="advertisement">
              
                <div className="desktop-advert">
                <a href='https://www.biomedista.com' target='_blank'>
                  <span>Advertisement</span>
                 <img style={{boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"}} src="https://medrais.com/medrais_images/biomedista1.jpeg" alt="" />
                 </a>
                </div>
                
                <div className="tablet-advert">
                  <span>Advertisement</span>
                  <img src="upload/addsense/468x60-white.jpg" alt="" />
                </div>
                <div className="mobile-advert">
                  <span>Advertisement</span>
                  <img src="upload/addsense/300x250.jpg" alt="" />
                </div>
              </div>
              {/* End Advertisement */}
              
              {/* Article Box */}
              <div className="article-box">
                <div className="title-section">
                  <h1>
                    <span>Latest Articles</span>
                  </h1>
                </div>

                {/* Announcement Cards */}
                {selectedAnnouncements.map((announcement) => (
                  <AnnouncementCard key={announcement.id} data={announcement} />
                ))}
              </div>
              {/* End Article Box */}

              {/* Pagination Box */}
              <div className="pagination-box">
                <ul className="pagination-list" style={{ display: "flex", listStyle: "none" }}>
                  {/* Previous Button */}
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      style={{
                        backgroundColor: currentPage === 1 ? "#f0f0f0" : "#222222",
                        color: "#fff",
                        border: "1px solid #ddd",
                        padding: "5px 10px",
                        cursor: currentPage === 1 ? "not-allowed" : "pointer",
                      }}
                    >
                      Prev
                    </button>
                  </li>

                  {/* Sayfa numaralarını göster */}
                  {pageNumbers().map((page, index) => (
                    <li key={index}>
                      <button
                        className={currentPage === page ? "active" : ""}
                        onClick={() => handlePageChange(page)}
                        style={{
                          backgroundColor: currentPage === page ? "#0056b3" : "#f0f0f0",
                          color: currentPage === page ? "#fff" : "#000",
                          border: "1px solid #ddd",
                          padding: "5px 10px",
                          cursor: "pointer",
                          margin: "0 5px",
                        }}
                      >
                        {page}
                      </button>
                    </li>
                  ))}

                  {/* Next Button */}
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      style={{
                        backgroundColor: currentPage === totalPages ? "#f0f0f0" : "#222222",
                        color: "#fff",
                        border: "1px solid #ddd",
                        padding: "5px 10px",
                        cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
                <p>Page {currentPage} of {totalPages}</p>
              </div>
              {/* End Pagination Box */}
            </div>
            {/* End block content */}
          </div>

          {/* Sidebar Part */}
          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default MainAnnouncements;
